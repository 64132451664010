.user-height-list {
    height: 65%;
}

.list-none {
    display: none;
}

.back-chat-list {
    display: none;
}

@media (max-width: 970px) {
    .list-chat {
        display: block;
        // transform: translateX(100%);
    }
    .chat-message {
        display: none;
    }
    .back-chat-list {
        display: block;
    }
  }


  @media (min-width: 970px) {
    .list-none {
      display: block ;
    }
  }
