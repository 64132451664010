@import "../../../@core/scss/base/core/colors/palette-variables.scss";

.rdt_TableHead {
	margin-bottom: 20px;
	background: map-get($primary-color, "lighten-5");
	border-radius: 5px;
}

.rdt_TableHeadRow {
	background-color: inherit;
	border-radius: 5px;
}

.rdt_TableHeader {
	margin-bottom: 20px;
}

.rdt_TableBody {
	// position: absolute;
	border-radius: 5px;
	top: 50px;
}

.rdt_TableRow {
	border-radius: 5px;
}

.rdt_TableCol {
	border-radius: 5px;
}

.rdt_Table {
	background-color: inherit;
}

//condition for process status
.status-box-process {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, #00A7E5 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-process:hover {
	background: linear-gradient(to left, #00A7E5 40px, transparent 60px);
}
.status-text-process {
	margin: 0px;
	font-size: 9px;
	position: relative;
	color: white;
	opacity: 0;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-process:hover .status-text-process {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}


// condition for done status
.status-box-done {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, #57cc99 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-done:hover {
	background: linear-gradient(to left, #57cc99 40px, transparent 60px);
}
.status-text-done {
	margin: 0px;
	font-size: 9px;
	position: relative;
	color: white;
	opacity: 0;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-done:hover .status-text-done {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}


//condition for approve status
.status-box-approve {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, #004225 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-approve:hover {
	background: linear-gradient(to left, #004225 40px, transparent 60px);
}
.status-text-approve {
	margin: 0px;
	font-size: 9px;
	position: relative;
	color: white;
	opacity: 0;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-approve:hover .status-text-approve {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}

//condition for grace period status
.status-box-grace-period {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, #FFA500 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-grace-period:hover {
	background: linear-gradient(to left, #FFA500 40px, transparent 60px);
}
.status-text-grace-period {
	margin: 0px;
	font-size: 9px;
	position: relative;
	color: white;
	opacity: 0;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-grace-period:hover .status-text-grace-period {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}

//condition for decline status
.status-box-decline {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, #000000 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-decline:hover {
	background: linear-gradient(to left, #000000 40px, transparent 60px);
}
.status-text-decline {
	margin: 0px;
	font-size: 9px;
	position: relative;
	color: white;
	opacity: 0;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-decline:hover .status-text-decline {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}

.status-box-not_completed {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, #dd2727 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-not_completed:hover {
	background: linear-gradient(to left, #dd2727 40px, transparent 60px);
}
.status-text-not_completed {
	margin: 0px;
	font-size: 9px;
	position: relative;
	color: white;
	opacity: 0;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-not_completed:hover .status-text-not_completed {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}

//condition for need approve
.status-box-need-approve {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, #D8D9DA 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-need-approve:hover {
	background: linear-gradient(to left, #D8D9DA 40px, transparent 60px);
}
.status-text-need-approve {
	margin: 0px;
	font-size: 9px;
	position: relative;
	// color: white;
	opacity: 0;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-need-approve:hover .status-text-need-approve {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}


//condition for grace period
.status-box-grace-period {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, #E25E3E 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-grace-period:hover {
	background: linear-gradient(to left, #E25E3E 40px, transparent 60px);
}
.status-text-grace-period {
	margin: 0px;
	font-size: 9px;
	position: relative;
	color: white;
	opacity: 0;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-grace-period:hover .status-text-grace-period {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}

//condition for correct 
.status-box-correct {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, #FBD85D 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-correct:hover {
	background: linear-gradient(to left, #FBD85D 40px, transparent 60px);
}
.status-text-correct {
	margin: 0px;
	font-size: 9px;
	position: relative;
	opacity: 0;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-correct:hover .status-text-correct {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}


//condition for draft 
.status-box-draft {
	width: 60px;
	height: 100%;
	background: linear-gradient(to left, $primary 20px, transparent 20px);
	transition: background 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	border-radius: 0 10px 10px 0;
	/* Diperlukan untuk overlay */
}
.status-box-draft:hover {
	background: linear-gradient(to left, $primary 40px, transparent 60px);
}
.status-text-draft {
	margin: 0px;
	font-size: 9px;
	position: relative;
	opacity: 0;
	color: white;
	/* Teks diatur menjadi tidak terlihat */
	transition: opacity 0.3s;
	/* Efek transisi opacity saat dihover */
}
.status-box-draft:hover .status-text-draft {
	opacity: 1;
	/* Tampilkan teks saat elemen dihover */
}