.chat-hover-none {
    display: none !important;
}

.list-none {
    display: none;
}

.back-chat-list {
    display: none;
}

@media (max-width: 900px) {
    .list-chat {
        display: block;
        // transform: translateX(100%);
    }
    .back-chat-list {
        display: block;
    }
  }


  @media (min-width: 900px) {
    .list-none {
      display: block ;
    }
  }

  