.text-container {
    height: 100%; /* Sesuaikan lebar sesuai kebutuhan */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; /* Menambahkan titik-titik (...) untuk teks yang berlebihan */
  }
  
  .text-container:hover .text {
    white-space: normal; /* Mengembalikan teks ke tampilan normal saat dihover */
    overflow: visible;
  }

  .sidebar {
    width: 100%;
    height: 80vh !important;
    background-color:white;
    color: white;
    // position: fixed;
    // top: 0;
    transition: left 0.3s ease-in-out;
    margin-right: 20px;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .sidebar .close-button {
    position: absolute;
    // top: 10px;
    right: 10px;
    cursor: pointer;
  }

  @media (max-width: 970px) {
    .sidebar {
      left: -1000px;
    }
  
    .app.open .sidebar {
      left: 0;
    }

    .sidebar-toggle {
      display: block !important;
    }
  }