@import "../../../@core/scss/base/core/colors/palette-variables.scss";

.rdt_TableHead {
    margin-bottom: 20px;
    background: map-get($primary-color, "lighten-5" );
    border-radius: 5px;
}

.rdt_TableHeadRow {
    background-color: inherit;
    border-radius: 5px;
}

.rdt_TableHeader {
    margin-bottom: 20px;
}

.rdt_TableBody {
    // position: absolute;
    border-radius: 5px;
    top: 50px;

}

.rdt_TableRow {
    border-radius: 5px;
    text-align: center;

}
.rdt_TableCol{
    border-radius: 5px;
}

.rdt_Table {
    background-color: inherit;


}

.custom-bg-success {
    background:linear-gradient(90deg, #ffffff 99%, map-get($success-color, "base" ) 1%);
}

.custom-bg-warning {
    background:linear-gradient(90deg, #ffffff 99%, map-get($warning-color, "base" ) 1%);
}

.custom-bg-danger {
    background:linear-gradient(90deg, #ffffff 99%, map-get($danger-color, "base" ) 1%);
}

.rdt_TableCol div:first-child {
    white-space: normal !important;
    overflow: visible !important;
    text-align: center;
}