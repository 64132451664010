.lingkaran-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Atur ukuran wadah sesuai keinginan Anda */
}

.lingkaran {
    width: 40px; /* Atur ukuran lingkaran sesuai keinginan Anda */
    height: 40px; /* Atur ukuran lingkaran sesuai keinginan Anda */
    border-radius: 50%; /* Mengatur bentuk lingkaran */

    box-shadow: inset 1px 1px 2px 2px rgba(60, 60, 60, 0.25); /* Efek shadow 3D */
    display: flex;
    justify-content: center;
    align-items: center;
}

.lingkaran img {
    max-width: 50%; /* Atur ukuran logo sesuai keinginan Anda */
}