/* global styles for orgchart */
.orgchart-container {
    box-sizing: border-box;
    position: relative;
    margin: 10px;
    height: 300px;
    background-color: #283046;
    border: 2px dashed #aaa;
    border-radius: 5px;
    overflow: auto;
    text-align: center;
}

.orgchart {
    box-sizing: border-box;
    display: inline-block;
    // background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%);
    background-size: 10px 10px;
    border: 1px dashed rgba(0, 0, 0, 0);
    padding: 20px 20px 0 20px;
}

.orgchart-container .hidden {
    display: none !important;
}

.orgchart-container>.oc-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.orgchart-container>.oc-mask .spinner {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
}

.orgchart-container>.oc-mask .spinner::before {
    width: 100px;
    height: 100px;
    border-width: 10px;
    border-radius: 50px;
    border-top-color: rgba(68, 157, 68, 0.8);
    border-bottom-color: rgba(68, 157, 68, 0.8);
    border-left-color: rgba(68, 157, 68, 0.8);
}