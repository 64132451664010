@import "../../scss/base/core/colors/palette-variables.scss";

.card-body-sumber{
    box-shadow: none !important;
    :hover {
        border : 2px solid map-get($primary-color, "base");
        border-radius: 5px;
        
    }
    .card-body{
        :hover {
            border : 0px solid map-get($primary-color, "light" );
    }
    }
}