.spinner {
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Animasi */
  }
  
  /* Ukuran Kustom */
  .sm {
    width: 14px;
    height: 14px;
    border: 2px solid; /* Border size */
  }
  
  .md {
    width: 28px;
    height: 28px;
    border: 3px solid; /* Border size */
  }
  
  /* Mode Light */
  .light {
    border-color: rgba(0, 0, 0, 0.5);
    border-left-color: #FFFFFF; /* Warna spinner untuk mode light */
  }
  
  /* Mode Dark */
  .dark {
    border-color: rgba(255, 255, 255, 1);
    border-left-color: rgba(255, 255, 255, 0); /* Warna spinner untuk mode dark */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  